import { message } from 'antd';
import { getLoggedInUser } from 'api/auth';
import { getToken } from 'helpers/auth.helpers';
import { createContext, useContext } from 'react';
import React, { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

export const AuthContext = createContext({
  user: undefined,
  isLoading: false,
  setUser: () => {},
});

export const useAuthContext = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [userData, setUserData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const authToken = getToken();

  const fetchLoggedInUser = async () => {
    setIsLoading(true);
    try {
      const data = await getLoggedInUser();
      setUserData(data);
    } catch (error) {
      message.error('Error while getting logged in user details');
    } finally {
      setIsLoading(false);
    }
  };

  const handleUser = (user) => {
    setUserData(user);
  };

  useEffect(() => {
    if (authToken) {
      fetchLoggedInUser();
    }
  }, [authToken]);

  return (
    <ErrorBoundary fallback={<p>Something went wrong</p>}>
      <AuthContext.Provider
        value={{ user: userData, setUser: handleUser, isLoading }}
      >
        {children}
      </AuthContext.Provider>
    </ErrorBoundary>
  );
};
