import { getWorkspaces } from 'api/workspaces';
import React, { createContext, useEffect, useState } from 'react';

import { useAuthContext } from './AuthContext';

export const WorkspaceContext = createContext();

export const WorkspaceProvider = ({ children }) => {
  const { user } = useAuthContext();
  const [workspace, setWorkspace] = useState('');
  const [workspaces, setWorkspaces] = useState([]);

  useEffect(() => {
    const loadWorkspaces = async () => {
      try {
        if (user) {
          const userWorkspaces = await getWorkspaces();
          const formattedWorkspaces = userWorkspaces.data.map((item) => ({
            id: item.id,
            name: item.attributes.name,
          }));
          setWorkspaces(formattedWorkspaces);
          const savedWorkspace = localStorage.getItem('workspace');
          if (formattedWorkspaces.length > 0) {
            if (savedWorkspace) {
              setWorkspace(JSON.parse(savedWorkspace));
            } else {
              setWorkspace(formattedWorkspaces[0]);
            }
          }
        }
      } catch (error) {
        console.error('Failed to load workspaces: ', error);
      }
    };

    loadWorkspaces();
  }, [user]);

  return (
    <WorkspaceContext.Provider value={{ workspace, setWorkspace, workspaces }}>
      {children}
    </WorkspaceContext.Provider>
  );
};
