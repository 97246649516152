import Cookies from 'js-cookie';

export const setToken = (token) => {
  if (token) {
    Cookies.set('jwt', token, { secure: true, sameSite: 'strict' });
  }
};

export const removeToken = () => {
  Cookies.remove('jwt');
};

export const getAllCookies = () => {
  return Cookies.get();
};

export const getToken = () => {
  return Cookies.get('jwt');
};

export const displayUserInfo = (user) => {
  if (user === undefined || user === null) {
    return '';
  }
  if (user.name_first !== null && user.name_last !== null) {
    return `${user.name_first} ${user.name_last}`;
  } else if (user.username !== null) {
    return user.username;
  } else {
    return user.email;
  }
};
