import { ClickAwayListener } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ChevronDown from 'assets/icons/ChevronDown.svg';
import logo from 'assets/logo.jpg';
import { useAuthContext } from 'context/AuthContext';
import { WorkspaceContext } from 'context/WorkspaceContext';
import { removeToken } from 'helpers/auth.helpers';
import { displayUserInfo } from 'helpers/auth.helpers';
import { useContext, useState } from 'react';
import { FiMenu } from 'react-icons/fi';
import { Link, useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

import './Navbar.scss';

function Navbar() {
  const { user, setUser } = useAuthContext();
  const { workspace, setWorkspace, workspaces } = useContext(WorkspaceContext);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [leftExpanded, setLeftExpanded] = useState(false);
  const [rightExpanded, setRightExpanded] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setOpen(open);
  };
  const handleLogout = () => {
    removeToken();
    setUser(null);
    localStorage.clear();
    navigate('/login', { replace: true });
  };
  const handleWorkspaceChange = (workspace) => {
    setWorkspace(workspace);
    localStorage.setItem('workspace', JSON.stringify(workspace));
    setLeftExpanded(false);
  };

  return (
    <div className="navbar" data-testid="navbar">
      <a href="/dashboard">
        <img className="logo" src={logo} alt="Appraisal Bureau Logo" />
      </a>
      <ClickAwayListener onClickAway={() => setLeftExpanded(false)}>
        <Accordion
          expanded={leftExpanded}
          disableGutters
          sx={{
            backgroundColor: 'inherit',
            color: 'inherit',
            minHeight: 0,
            overflow: 'auto',
            position: 'absolute',
            left: 40,
            top: 0,
          }}
          className="left navbar-text"
          id="user-dropdown"
        >
          <AccordionSummary
            sx={{ minHeight: 0 }}
            onClick={() => setLeftExpanded(true)}
          >
            <span className="navbar-text">
              {displayUserInfo(user)}'s Portfolios | {workspace.name}
              <ReactSVG src={ChevronDown} className="icon" />
            </span>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
            }}
          >
            {workspaces.map((ws) => (
              <ListItemButton
                key={ws.id}
                value={ws.id}
                onClick={() => handleWorkspaceChange(ws)}
              >
                <ListItemText sx={{ fontFamily: 'inherit' }}>
                  {ws.name}
                </ListItemText>
              </ListItemButton>
            ))}
          </AccordionDetails>
        </Accordion>
      </ClickAwayListener>
      <ClickAwayListener onClickAway={() => setRightExpanded(false)}>
        <Accordion
          disableGutters
          expanded={rightExpanded}
          sx={{
            backgroundColor: 'inherit',
            color: 'inherit',
            minHeight: 0,
            overflow: 'auto',
            position: 'absolute',
            right: 0,
            top: 0,
          }}
          className="right navbar-text"
          id="user-dropdown"
        >
          <AccordionSummary
            sx={{ minHeight: 0 }}
            onClick={() => setRightExpanded(true)}
          >
            <span className="navbar-text">
              {displayUserInfo(user)}
              <ReactSVG src={ChevronDown} className="icon" />
            </span>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            <ListItemButton onClick={handleLogout}>
              <ListItemText sx={{ fontFamily: 'inherit' }}>Logout</ListItemText>
            </ListItemButton>
          </AccordionDetails>
        </Accordion>
      </ClickAwayListener>
      <FiMenu
        onClick={toggleDrawer(!open)}
        className="hamburger-menu right icon"
      />
      <Drawer
        anchor="right"
        variant="temporary"
        open={open}
        onClick={toggleDrawer(!open)}
      >
        <Box
          sx={{
            p: 2,
            height: 5,
          }}
        >
          <ListItemButton>
            <Link to="/dashboard">Dashboard</Link>
          </ListItemButton>
          <ListItemButton>
            <Link to="/portfolio">Portfolio</Link>
          </ListItemButton>
          {/* <ListItemButton>
            <ListItemText primary="Messages" />
          </ListItemButton> */}
          <ListItemButton>
            <ListItemText primary="Subscriptions" />
          </ListItemButton>
          <ListItemButton>
            <ListItemText primary="Workflows" />
          </ListItemButton>
          <ListItemButton>
            <ListItemText primary="API Keys" />
          </ListItemButton>
          <ListItemButton>
            <ListItemText primary="Settings" />
          </ListItemButton>
          <ListItemButton>
            <ListItemText primary="Logout" onClick={handleLogout} />
          </ListItemButton>
        </Box>
      </Drawer>
    </div>
  );
}

export default Navbar;
